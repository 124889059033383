import React from 'react';
import { graphql } from 'gatsby';
import Helmet from 'react-helmet';
import Layout from '../components/Layout';
import { TagLink } from '../components/TagLink';
import { basicList, contentArea } from '../constants';

const TagsPage = props => {
  const { allMdx } = props.data;

  return (
    <Layout>
      <Helmet title="Tags" />
      <div css={contentArea}>
        <h1>Tags</h1>
        <ul css={basicList}>
          {allMdx.group.map(tag => (
            <li key={tag.fieldValue}>
              <TagLink key={tag} tag={tag.fieldValue} /> ({tag.totalCount})
            </li>
          ))}
        </ul>
      </div>
    </Layout>
  );
};

export default TagsPage;

export const pageQuery = graphql`
  {
    allMdx(limit: 2000, filter: { frontmatter: { draft: { ne: true } } }) {
      group(field: frontmatter___tags) {
        fieldValue
        totalCount
      }
    }
  }
`;
